import { signOut } from "next-auth/react";
import { toast } from "sonner";
import { useTranslations } from "next-intl";
import { getErrorMessage } from "@/utils";

const useErrorHandling = () => {
  const t = useTranslations();
  const onError = (error: any) => {
    console.log(error);
    if (error?.response.status === 401) {
      signOut({
        callbackUrl: `/login?error=${
          error?.response.data.error ||
          t(getErrorMessage(error?.response.data.status.type))
        }`,
      });
    } else if (error?.response.status === 422) {
      toast.error(t("Profile.incorrectPassword"));
    } else {
      toast.error(
        error?.response.data.error ||
          error?.response.data.data.message ||
          t(getErrorMessage(error?.response.data.status.type)),
      );
    }
  };
  return {
    onError,
  };
};

export default useErrorHandling;
