"use client";

import { createContext, ReactNode, useContext, useEffect } from "react";
import { useSession } from "next-auth/react";
import { useQuery } from "react-query";
import { getBalance } from "@/sources/balance/api";
import { Balance } from "@/types/balance";
import { AxiosError } from "axios";
import useErrorHandling from "@/hooks/useErrorHandling";

type DefaultProps = {
  balance: Balance | undefined;
  isBalanceLoading: boolean;
  balanceRefetch: () => void;
};

const defaultValue: DefaultProps = {
  balance: undefined,
  isBalanceLoading: false,
  balanceRefetch: () => {},
};

const BalanceContext = createContext(defaultValue);

export const useBalanceContext = () => useContext(BalanceContext);

export const BalanceProvider = ({ children }: { children: ReactNode }) => {
  const { data: session } = useSession();
  const { onError } = useErrorHandling();

  const {
    data: balance,
    isLoading: isBalanceLoading,
    refetch: balanceRefetch,
  } = useQuery(
    ["balance"],
    () => {
      return session?.accessToken && getBalance(session.accessToken);
    },
    {
      enabled: true,
      refetchInterval: 5000,
      onError: (error: AxiosError) => onError(error),
    },
  );

  return (
    <BalanceContext.Provider
      value={{
        balance,
        isBalanceLoading,
        balanceRefetch,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceProvider;
