import { getPageableParams, rewardsAxios } from "utils";
import * as routes from "./routes";
import type { JWT } from "next-auth/jwt";
import { User } from "@/types/user";

export const getUser = (token: JWT) =>
  rewardsAxios
    .get(routes.GET_USER, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data.data as User);

export const putProfileEdit = (body: any, token: JWT | undefined) =>
  rewardsAxios
    .put(routes.PUT_USER, body, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);

export const putProfilePictureDelete = (body: any, token: JWT | undefined) =>
  rewardsAxios
    .put(routes.PUT_USER, body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data);

export const putChangePassword = (body: any, token: JWT | undefined) =>
  rewardsAxios
    .put(routes.PUT_CHANGE_PASSWORD, body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data);

export const putResetPassword = (body: any, token: JWT | undefined) =>
  rewardsAxios
    .put(routes.PUT_CHANGE_PASSWORD, body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data);

export const deleteAccount = (body: any, token: JWT | undefined) =>
  rewardsAxios
    .delete(routes.DELETE_ACCOUNT, {
      data: body,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data);

export const postResetPassword = (body: { user: { email: string } }) =>
  rewardsAxios
    .post(routes.POST_RESET_PASSWORD, body, {
      headers: {},
    })
    .then((res) => res);

export const postResendConfirmation = (token: JWT | undefined) =>
  rewardsAxios
    .post(routes.POST_RESEND_CONFIRMATION, null, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res);

export const getConfirmations = (params: { confirmation_token: string }) =>
  rewardsAxios
    .get(routes.GET_CONFIRMATIONS, {
      headers: {},
      params: getPageableParams(params),
    })
    .then((res) => res.data.data);

export const postRefreshToken = (token: JWT | undefined) =>
  rewardsAxios
    .post(routes.POST_REFRESH_TOKEN, null, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res);
