import { getPageableParams, rewardsAxios } from "utils";
import * as routes from "./routes";
import type { JWT } from "next-auth/jwt";
import { Balance, BalanceHistory, BalanceShop } from "@/types/balance";
import { StatusStepType } from "@/enums";

export const getBalance = (token: JWT) =>
  rewardsAxios
    .get(routes.GET_BALANCE, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data.data as Balance);

export const getBalanceShop = (token: JWT) =>
  rewardsAxios
    .get(routes.GET_BALANCE_SHOP, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data.data as BalanceShop[]);

export const getBalanceHistory = (
  token: JWT,
  params: {
    page: number;
    per_page: number;
    status: StatusStepType;
  },
) =>
  rewardsAxios
    .get(routes.GET_BALANCE_HISTORY, {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: getPageableParams(params),
    })
    .then((res) => res.data.data as BalanceHistory);
