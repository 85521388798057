"use client";

import { ReactNode } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import BalanceProvider from "@/app/context/BalanceProvider";
import UserProvider from "@/app/context/UserProvider";

export default function Provider({
  children,
}: {
  children: ReactNode;
}): ReactNode {
  return (
    <SkeletonTheme baseColor="#F5F5F5" highlightColor="#FAFAFA">
      <BalanceProvider>
        <UserProvider>{children}</UserProvider>
      </BalanceProvider>
    </SkeletonTheme>
  );
}
